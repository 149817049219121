@font-face {
  font-family: 'Quaaykop';
  src: local('Quaaykop'), url(./fonts/QuaaykopMedium-2OxRW.ttf) format('truetype');
}

@font-face {
  font-family: 'Theano Didot';
  src: local('Theano Didot'), url(./fonts/TheanoDidot-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'EB Garamond';
  src: local('EB Garamond'), url(./fonts/EBGaramond-VariableFont_wght.ttf) format('truetype');
}

a {
  color: inherit;
  text-decoration: underline 0.06em rgba(0, 0, 0, 0);
  transition: text-decoration-color 600ms;
  text-underline-offset: 0.2em
}

a:hover {
  color: inherit;
  text-decoration-color: #f5cf78;

}

body {
  max-width: 100%;
  height: 100%;
  overflow-x: clip;
  font-family: 'EB Garamond', serif;
  font-size: 1.3em;
  line-height: 1.65em;
  background-color: #F4F3F2;
}

form {
  background-color: #dadada;
  text-align: justify;
  font-family: 'EB Garamond';
}

footer {
  margin-top: auto;
  background-color: black;
  color: #F4F3F2;
}

h1 {
  font-family: 'EB Garamond';
  font-weight: bold;
}

h2 {
  font-family: 'EB Garamond';
}

h3 {
  font-family: 'EB Garamond';
  font-weight: 400;
}

::selection {
  background-color: black;
  color: #f5cf78;
}

.about {
  text-align: justify;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.available {
  font-size: 1.2em;
  /* text-wrap: wrap; */
}

.blogBody {
  text-align: justify;
}

.blurb {
  font-size: 1.15em;
  margin: 0;
}

.book {
  max-height: 340px;
}

.btn {
  font-family: 'EB Garamond';
  font-size: large;
  color: black;
  background-color: #dadada;
  border-radius: 0;
  transition: none;
  outline: none;
}

.btn:hover {
  background-color: black;
  color: #F4F3F2;
}

.count {
  font-size: .8em;
  color: #10100f92;
}

.cprt {
  color: #F4F3F2;
  font-size: .5em;
}

.dice {
  max-height: 60px;
}

.fool {
  max-height: 200px;
}

.form-control {
  box-shadow: none;
  border-radius: 0;
  font-family: 'EB Garamond';
  font-size: .9em;
}

.form-control:focus {
  border: 1px solid black;
  box-shadow: none;
  transition: border-color .4s ease-in-out;
}

.form-label {
  font-size: .9em;
}

.foundation {
  border-top: 1px solid black;
  box-shadow: 0 42vh 1px black;
}


.hand {
  font-family: serif;
  font-size: 1.3em;
}

.hook {
  background-color: black;
  color: #F4F3F2;
}

.moon {
  max-height: 100px;
}

.name {
  max-height: 80px;
}

.nav {
  font-family: 'Theano Didot', sans-serif;
  font-size: smaller;
}

.scrollTop {
  cursor: pointer;
  max-height: 55px;
}

.shortAbout {
  margin: 0 10em 4em;
  text-align: justify;
}

.social-icon {
  max-height: 30px;
}

/*
NEED MEDIA QUERY FOR VIEWPORT WIDTH.
BASE OFF BOOTSTRAP GRID OPTIONS:

XS<576px

SM>=576px

MD>=768px

LG>=992px
*/

@media only screen and (min-width: 576px) {
  form {
    margin: 2em 1em 2em
  }

  .blogBody,
  .shortAbout {
    margin: 1em 3em 3em;
  }

  .about {
    margin: 0 1em 0;
  }
}

@media only screen and (min-width: 768px) {
  form {
    margin: 2em 3em 2em
  }

  .blogBody,
  .shortAbout {
    margin: 1em 6.5em 2em;
  }
}

@media only screen and (min-width: 992px) {

  .blogBody,
  .shortAbout {
    margin: 1em 9em 3em;
  }

  .about {
    margin: 0 2em 0;
  }
}

@media only screen and (min-width: 1200px) {
  form {
    margin: 2em 7em 2em
  }

  .blogBody,
  .shortAbout {
    margin: 1em 15em 3em;
  }

  .about {
    margin: 0 5em 0;
  }
}

@media only screen and (max-width: 576px) {
  .available {
    font-size: .8em;
  }

  .blogBody,
  .shortAbout {
    margin: 0;
  }
}